import styled, { keyframes } from "styled-components";

const animStar = keyframes`
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-1200px);
    }
`

export const BgStars = styled.div`
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    background: transparent;
    animation: ${animStar} 50s linear infinite;
    box-shadow: 779px 1331px rgba(225, 225, 225, 0.4), 324px 42px rgba(225, 225, 225, 0.4), 303px 586px rgba(225, 225, 225, 0.4),
    1312px 276px rgba(225, 225, 225, 0.4), 451px 625px rgba(225, 225, 225, 0.4), 521px 1931px rgba(225, 225, 225, 0.4), 1087px 1871px rgba(225, 225, 225, 0.4),
    36px 1546px rgba(225, 225, 225, 0.4), 132px 934px rgba(225, 225, 225, 0.4), 1698px 901px rgba(225, 225, 225, 0.4), 1418px 664px rgba(225, 225, 225, 0.4),
    1448px 1157px rgba(225, 225, 225, 0.4), 1084px 232px rgba(225, 225, 225, 0.4), 347px 1776px rgba(225, 225, 225, 0.4), 1722px 243px rgba(225, 225, 225, 0.4),
    1629px 835px rgba(225, 225, 225, 0.4), 479px 969px rgba(225, 225, 225, 0.4), 1231px 960px rgba(225, 225, 225, 0.4), 586px 384px rgba(225, 225, 225, 0.4),
    164px 527px rgba(225, 225, 225, 0.4), 8px 646px rgba(225, 225, 225, 0.4), 1150px 1126px rgba(225, 225, 225, 0.4), 665px 1357px rgba(225, 225, 225, 0.4),
    1556px 1982px rgba(225, 225, 225, 0.4), 1260px 1961px rgba(225, 225, 225, 0.4), 1675px 1741px rgba(225, 225, 225, 0.4),
    1843px 1514px rgba(225, 225, 225, 0.4), 718px 1628px rgba(225, 225, 225, 0.4), 242px 1343px rgba(225, 225, 225, 0.4), 1497px 1880px rgba(225, 225, 225, 0.4),
    1364px 230px rgba(225, 225, 225, 0.4), 1739px 1302px rgba(225, 225, 225, 0.4), 636px 959px rgba(225, 225, 225, 0.4), 304px 686px rgba(225, 225, 225, 0.4),
    614px 751px rgba(225, 225, 225, 0.4), 1935px 816px rgba(225, 225, 225, 0.4), 1428px 60px rgba(225, 225, 225, 0.4), 355px 335px rgba(225, 225, 225, 0.4),
    1594px 158px rgba(225, 225, 225, 0.4), 90px 60px rgba(225, 225, 225, 0.4), 1553px 162px rgba(225, 225, 225, 0.4), 1239px 1825px rgba(225, 225, 225, 0.4),
    1945px 587px rgba(225, 225, 225, 0.4), 749px 1785px rgba(225, 225, 225, 0.4), 1987px 1172px rgba(225, 225, 225, 0.4), 1301px 1237px rgba(225, 225, 225, 0.4),
    1039px 342px rgba(225, 225, 225, 0.4), 1585px 1481px rgba(225, 225, 225, 0.4), 995px 1048px rgba(225, 225, 225, 0.4), 524px 932px rgba(225, 225, 225, 0.4),
    214px 413px rgba(225, 225, 225, 0.4), 1701px 1300px rgba(225, 225, 225, 0.4), 1037px 1613px rgba(225, 225, 225, 0.4), 1871px 996px rgba(225, 225, 225, 0.4),
    1360px 1635px rgba(225, 225, 225, 0.4), 1110px 1313px rgba(225, 225, 225, 0.4), 412px 1783px rgba(225, 225, 225, 0.4), 1949px 177px rgba(225, 225, 225, 0.4),
    903px 1854px rgba(225, 225, 225, 0.4), 700px 1936px rgba(225, 225, 225, 0.4), 378px 125px rgba(225, 225, 225, 0.4), 308px 834px rgba(225, 225, 225, 0.4),
    1118px 962px rgba(225, 225, 225, 0.4), 1350px 1929px rgba(225, 225, 225, 0.4), 781px 1811px rgba(225, 225, 225, 0.4), 561px 137px rgba(225, 225, 225, 0.4),
    757px 1148px rgba(225, 225, 225, 0.4), 1670px 1979px rgba(225, 225, 225, 0.4), 343px 739px rgba(225, 225, 225, 0.4), 945px 795px rgba(225, 225, 225, 0.4),
    576px 1903px rgba(225, 225, 225, 0.4), 1078px 1436px rgba(225, 225, 225, 0.4), 1583px 450px rgba(225, 225, 225, 0.4), 1366px 474px rgba(225, 225, 225, 0.4),
    297px 1873px rgba(225, 225, 225, 0.4), 192px 162px rgba(225, 225, 225, 0.4), 1624px 1633px rgba(225, 225, 225, 0.4), 59px 453px rgba(225, 225, 225, 0.4),
    82px 1872px rgba(225, 225, 225, 0.4), 1933px 498px rgba(225, 225, 225, 0.4), 1966px 1974px rgba(225, 225, 225, 0.4), 1975px 1688px rgba(225, 225, 225, 0.4),
    779px 314px rgba(225, 225, 225, 0.4), 1858px 1543px rgba(225, 225, 225, 0.4), 73px 1507px rgba(225, 225, 225, 0.4), 1693px 975px rgba(225, 225, 225, 0.4),
    1683px 108px rgba(225, 225, 225, 0.4), 1768px 1654px rgba(225, 225, 225, 0.4), 654px 14px rgba(225, 225, 225, 0.4), 494px 171px rgba(225, 225, 225, 0.4),
    1689px 1895px rgba(225, 225, 225, 0.4), 1660px 263px rgba(225, 225, 225, 0.4), 1031px 903px rgba(225, 225, 225, 0.4), 1203px 1393px rgba(225, 225, 225, 0.4),
    1333px 1421px rgba(225, 225, 225, 0.4), 1113px 41px rgba(225, 225, 225, 0.4), 1206px 1645px rgba(225, 225, 225, 0.4), 1325px 1635px rgba(225, 225, 225, 0.4),
    142px 388px rgba(225, 225, 225, 0.4), 572px 215px rgba(225, 225, 225, 0.4), 1535px 296px rgba(225, 225, 225, 0.4), 1419px 407px rgba(225, 225, 225, 0.4),
    242px 1471px rgba(225, 225, 225, 0.4), 654px 797px rgba(225, 225, 225, 0.4), 796px 594px rgba(225, 225, 225, 0.4), 1365px 678px rgba(225, 225, 225, 0.4),
    752px 23px rgba(225, 225, 225, 0.4), 1630px 541px rgba(225, 225, 225, 0.4), 982px 72px rgba(225, 225, 225, 0.4), 1733px 1831px rgba(225, 225, 225, 0.4),
    21px 412px rgba(225, 225, 225, 0.4), 775px 998px rgba(225, 225, 225, 0.4), 335px 1945px rgba(225, 225, 225, 0.4), 264px 583px rgba(225, 225, 225, 0.4),
    158px 1311px rgba(225, 225, 225, 0.4), 528px 164px rgba(225, 225, 225, 0.4), 1978px 574px rgba(225, 225, 225, 0.4), 717px 1203px rgba(225, 225, 225, 0.4),
    734px 1591px rgba(225, 225, 225, 0.4), 1555px 820px rgba(225, 225, 225, 0.4), 16px 1943px rgba(225, 225, 225, 0.4), 1625px 1177px rgba(225, 225, 225, 0.4),
    1236px 690px rgba(225, 225, 225, 0.4), 1585px 1590px rgba(225, 225, 225, 0.4), 1737px 1728px rgba(225, 225, 225, 0.4), 721px 698px rgba(225, 225, 225, 0.4),
    1804px 1186px rgba(225, 225, 225, 0.4), 166px 980px rgba(225, 225, 225, 0.4), 1850px 230px rgba(225, 225, 225, 0.4), 330px 1712px rgba(225, 225, 225, 0.4),
    95px 797px rgba(225, 225, 225, 0.4), 1948px 1078px rgba(225, 225, 225, 0.4), 469px 939px rgba(225, 225, 225, 0.4), 1269px 1899px rgba(225, 225, 225, 0.4),
    955px 1220px rgba(225, 225, 225, 0.4), 1137px 1075px rgba(225, 225, 225, 0.4), 312px 1293px rgba(225, 225, 225, 0.4), 986px 1762px rgba(225, 225, 225, 0.4),
    1103px 1238px rgba(225, 225, 225, 0.4), 428px 1993px rgba(225, 225, 225, 0.4), 355px 570px rgba(225, 225, 225, 0.4), 977px 1836px rgba(225, 225, 225, 0.4),
    1395px 1092px rgba(225, 225, 225, 0.4), 276px 913px rgba(225, 225, 225, 0.4), 1743px 656px rgba(225, 225, 225, 0.4), 773px 502px rgba(225, 225, 225, 0.4),
    1686px 1322px rgba(225, 225, 225, 0.4), 1516px 1945px rgba(225, 225, 225, 0.4), 1334px 501px rgba(225, 225, 225, 0.4), 266px 156px rgba(225, 225, 225, 0.4),
    455px 655px rgba(225, 225, 225, 0.4), 798px 72px rgba(225, 225, 225, 0.4), 1059px 1259px rgba(225, 225, 225, 0.4), 1402px 1687px rgba(225, 225, 225, 0.4),
    236px 1329px rgba(225, 225, 225, 0.4), 1455px 786px rgba(225, 225, 225, 0.4), 146px 1228px rgba(225, 225, 225, 0.4), 1851px 823px rgba(225, 225, 225, 0.4),
    1062px 100px rgba(225, 225, 225, 0.4), 1220px 953px rgba(225, 225, 225, 0.4), 20px 1826px rgba(225, 225, 225, 0.4), 36px 1063px rgba(225, 225, 225, 0.4),
    1525px 338px rgba(225, 225, 225, 0.4), 790px 1521px rgba(225, 225, 225, 0.4), 741px 1099px rgba(225, 225, 225, 0.4), 288px 1489px rgba(225, 225, 225, 0.4),
    700px 1060px rgba(225, 225, 225, 0.4), 390px 1071px rgba(225, 225, 225, 0.4), 411px 1036px rgba(225, 225, 225, 0.4), 1853px 1072px rgba(225, 225, 225, 0.4),
    1446px 1085px rgba(225, 225, 225, 0.4), 1164px 874px rgba(225, 225, 225, 0.4), 924px 925px rgba(225, 225, 225, 0.4), 291px 271px rgba(225, 225, 225, 0.4),
    1257px 1964px rgba(225, 225, 225, 0.4), 1580px 1352px rgba(225, 225, 225, 0.4), 1507px 1216px rgba(225, 225, 225, 0.4), 211px 956px rgba(225, 225, 225, 0.4),
    985px 1195px rgba(225, 225, 225, 0.4), 975px 1640px rgba(225, 225, 225, 0.4), 518px 101px rgba(225, 225, 225, 0.4), 663px 1395px rgba(225, 225, 225, 0.4),
    914px 532px rgba(225, 225, 225, 0.4), 145px 1320px rgba(225, 225, 225, 0.4), 69px 1397px rgba(225, 225, 225, 0.4), 982px 523px rgba(225, 225, 225, 0.4),
    257px 725px rgba(225, 225, 225, 0.4), 1599px 831px rgba(225, 225, 225, 0.4), 1636px 1513px rgba(225, 225, 225, 0.4), 1250px 1158px rgba(225, 225, 225, 0.4),
    1132px 604px rgba(225, 225, 225, 0.4), 183px 102px rgba(225, 225, 225, 0.4), 1057px 318px rgba(225, 225, 225, 0.4), 1247px 1835px rgba(225, 225, 225, 0.4),
    1983px 1110px rgba(225, 225, 225, 0.4), 1077px 1455px rgba(225, 225, 225, 0.4), 921px 1770px rgba(225, 225, 225, 0.4), 806px 1350px rgba(225, 225, 225, 0.4),
    1938px 1992px rgba(225, 225, 225, 0.4), 855px 1260px rgba(225, 225, 225, 0.4), 902px 1345px rgba(225, 225, 225, 0.4), 658px 1908px rgba(225, 225, 225, 0.4),
    1845px 679px rgba(225, 225, 225, 0.4), 712px 1482px rgba(225, 225, 225, 0.4), 595px 950px rgba(225, 225, 225, 0.4), 1784px 1992px rgba(225, 225, 225, 0.4),
    1847px 1785px rgba(225, 225, 225, 0.4), 691px 1004px rgba(225, 225, 225, 0.4), 175px 1179px rgba(225, 225, 225, 0.4), 1666px 1911px rgba(225, 225, 225, 0.4),
    41px 61px rgba(225, 225, 225, 0.4), 971px 1080px rgba(225, 225, 225, 0.4), 1830px 1450px rgba(225, 225, 225, 0.4), 1351px 1518px rgba(225, 225, 225, 0.4),
    1257px 99px rgba(225, 225, 225, 0.4), 1395px 1498px rgba(225, 225, 225, 0.4), 1117px 252px rgba(225, 225, 225, 0.4), 1779px 597px rgba(225, 225, 225, 0.4),
    1346px 729px rgba(225, 225, 225, 0.4), 1108px 1144px rgba(225, 225, 225, 0.4), 402px 691px rgba(225, 225, 225, 0.4), 72px 496px rgba(225, 225, 225, 0.4),
    1673px 1604px rgba(225, 225, 225, 0.4), 1497px 974px rgba(225, 225, 225, 0.4), 1865px 1664px rgba(225, 225, 225, 0.4), 88px 806px rgba(225, 225, 225, 0.4),
    918px 77px rgba(225, 225, 225, 0.4), 244px 1118px rgba(225, 225, 225, 0.4), 256px 1820px rgba(225, 225, 225, 0.4), 1851px 1840px rgba(225, 225, 225, 0.4),
    605px 1851px rgba(225, 225, 225, 0.4), 634px 383px rgba(225, 225, 225, 0.4), 865px 37px rgba(225, 225, 225, 0.4), 943px 1024px rgba(225, 225, 225, 0.4),
    1951px 177px rgba(225, 225, 225, 0.4), 1097px 523px rgba(225, 225, 225, 0.4), 985px 1700px rgba(225, 225, 225, 0.4), 1243px 122px rgba(225, 225, 225, 0.4),
    768px 1070px rgba(225, 225, 225, 0.4), 468px 194px rgba(225, 225, 225, 0.4), 320px 1867px rgba(225, 225, 225, 0.4), 1850px 185px rgba(225, 225, 225, 0.4),
    380px 1616px rgba(225, 225, 225, 0.4), 468px 1294px rgba(225, 225, 225, 0.4), 1122px 1743px rgba(225, 225, 225, 0.4), 884px 299px rgba(225, 225, 225, 0.4),
    1300px 1917px rgba(225, 225, 225, 0.4), 1860px 396px rgba(225, 225, 225, 0.4), 1270px 990px rgba(225, 225, 225, 0.4), 529px 733px rgba(225, 225, 225, 0.4),
    1975px 1347px rgba(225, 225, 225, 0.4), 1885px 685px rgba(225, 225, 225, 0.4), 226px 506px rgba(225, 225, 225, 0.4), 651px 878px rgba(225, 225, 225, 0.4),
    1323px 680px rgba(225, 225, 225, 0.4), 1284px 680px rgba(225, 225, 225, 0.4), 238px 1967px rgba(225, 225, 225, 0.4), 911px 174px rgba(225, 225, 225, 0.4),
    1111px 521px rgba(225, 225, 225, 0.4), 1150px 85px rgba(225, 225, 225, 0.4), 794px 502px rgba(225, 225, 225, 0.4), 484px 1856px rgba(225, 225, 225, 0.4),
    1809px 368px rgba(225, 225, 225, 0.4), 112px 953px rgba(225, 225, 225, 0.4), 590px 1009px rgba(225, 225, 225, 0.4), 1655px 311px rgba(225, 225, 225, 0.4),
    100px 1026px rgba(225, 225, 225, 0.4), 1803px 352px rgba(225, 225, 225, 0.4), 865px 306px rgba(225, 225, 225, 0.4), 1077px 1019px rgba(225, 225, 225, 0.4),
    1335px 872px rgba(225, 225, 225, 0.4), 1647px 1298px rgba(225, 225, 225, 0.4), 1233px 1387px rgba(225, 225, 225, 0.4), 698px 1036px rgba(225, 225, 225, 0.4),
    659px 1860px rgba(225, 225, 225, 0.4), 388px 1412px rgba(225, 225, 225, 0.4), 1212px 458px rgba(225, 225, 225, 0.4), 755px 1468px rgba(225, 225, 225, 0.4),
    696px 1654px rgba(225, 225, 225, 0.4), 1144px 60px rgba(225, 225, 225, 0.4);
`